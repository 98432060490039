<template>
  <div class="sandbox-wrap c-content-wrapper">
    <div class="sandbox-wrap-function">
      <h1>一般のwebsocket処理</h1>
      <p>
        <web-socket-rpc />
      </p>
    </div>
    <div class="sandbox-wrap-function">
      <h1>学習処理</h1>
      <p>
        <learning-dev />
      </p>
    </div>
    <div class="sandbox-wrap-function">
      <h1>データセットアップロード処理</h1>
      <p>
        <uploader-dev />
      </p>
    </div>
    <div class="sandbox-wrap-function">
      <h1>プロジェクトテンプレートの画像アップロード処理</h1>
      <p>
        <image-uploader-dev />
      </p>
    </div>
    <div class="sandbox-wrap-function">
      <h1>推論処理</h1>
      <p>
        <inference-dev />
      </p>
    </div>
  </div>
</template>

<script>
import ImageUploaderDev from '@/components/molecules/dev/ImageUploaderDev.vue'
import LearningDev from '@/components/molecules/dev/LearningDev'
import UploaderDev from '@/components/molecules/dev/UploaderDev'
import InferenceDev from '@/components/molecules/dev/InferenceDev'
import WebSocketRpc from '@/components/molecules/dev/wsapi'

export default {
  name: 'PageSandboxRpc',
  components: {
    ImageUploaderDev,
    LearningDev,
    UploaderDev,
    InferenceDev,
    WebSocketRpc
  }
}
</script>

<style lang="scss" scoped>
.sandbox-wrap {
  margin: $space-medium;
  &-function {
    padding: $space-medium;
    margin: 0 0 $space-medium;
    background: $background;
    border-radius: adjustVW(16);
  }
}
</style>
