<template>
  <div class="dev-infer">
    <input
      id="uploadInferenceData"
      ref="uploadInferenceData"
      type="file"
      class="upload-file"
      placeholder="ファイル選択"
      @change="inputFile($event.target.files[0])"
    >
    <p>
      selected:
      <span v-if="uploadFile != null" class="value-selected">
        {{ uploadFile.name }}
      </span>
    </p>
    <p>
      status:
      <span :class="`value-status--${inferenceProgressStatus}`">
        {{ inferenceProgressStatus }}
      </span>
    </p>
    <p>
      inference Id:<span class="value-inference-id">{{ inferenceId }}</span>
    </p>

    <textarea
      v-model="inferenceJSON"
      class="form-textarea"
      TODO
      :disabled="false"
      placeholder=""
      :rows="3"
      :max-rows="6"
    />

    <br >
    <button-main
      class="start-button"
      TODO
      :disabled="false"
      size="min"
      text="推論開始"
      @click="startInference"
    />
    <button-main
      class="reset-button"
      TODO
      :disabled="false"
      size="min"
      text="リセット"
      @click="clear"
    />
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: { buttonMain },
  name: '',
  props: {},
  data() {
    return {
      inferenceJSON: '',
      uploadFile: null
    }
  },
  computed: {
    ...mapState('inference', [
      'inferenceId',
      'classes',
      'predictedColumns',
      'predictedTable',
      'total',
      'finishColumnsList',
      'forecast',
      'inferencedColumns',
      'inferencedColumnsBase',
      'inferenceType',
      'inferencedTrainedAiId',
      'showOptimizationResult',
      'optimizationProgressStatus',
      'newlyPredictionColumns',
      'newlyFinishedPredictionColumns',
      'optimizationInProgress',
      'trainingNumIter',
      'trainingProgress',
      'optimizationNumIter',
      'optimizationProgress',
      'optimizationConditions',
      'optimizationResult',
      'optimizationSettings',
      'optimizationModelInfo',
      'optimizationConditionsInfo',
      'executedOptimizationConditions',
      'optimizationErrorMessage',
      'optimizationErrorManual',
      'jobInfo',
      'isOptimizationStopped',
      'inferenceProgressStatus'
    ])
  },
  methods: {
    ...mapActions('inference', [
      'inference',
      'reenterInference',
      'reenterInferenceInfo',
      'reset',
      'fetchTimeseriesResult',
      'fetchInferenceResultPaging',
      'optimizationAsync',
      'fetchOptimizationResult',
      'updateOptimzationConditions',
      'cancelWatchProgress',
      'stopOptimizationAsync'
    ]),
    clear() {
      this.inferenceJSON = ''
      this.uploadFile = null
      const file = document.getElementById('uploadInferenceData')
      file.value = ''
      this.reset()
    },
    startInference() {
      const request = JSON.parse(this.inferenceJSON)
      if (this.uploadFile) {
        request.file = this.uploadFile
      }
      this.inference(request)
    },
    inputFile(file) {
      this.uploadFile = file
    }
  }
}
</script>
