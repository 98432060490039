<template>
  <div class="web-socket-rpc">
    <div class="rpc-form">
      <label>
        timeout:
        <br >
        <input v-model="timeout" class="rpc-form__timeout" type="number" > s
      </label>
      <label>
        message:
        <br >
        <textarea v-model="request" class="rpc-form__request" />
      </label>
      <label>
        checkStrictMatchActionName:
        <input
          v-model="strictMatchActionName"
          class="rpc-form__strictMatchActionName"
          type="checkbox"
          :checked="false"
        >
        {{ strictMatchActionName }}
      </label>
      <checkbox-base
        v-model="strictMatchActionName"
        class="rpc-form__strictMatchActionNameClickable"
        text=""
      />
      <button-main
        class="rpc-form__call"
        text="call"
        size="min"
        @click="remoteCall"
      />
      <button-main
        class="rpc-form__reset"
        text="reset"
        size="min"
        @click="reset"
      />
    </div>
    <div class="rpc-calls">
      <div v-for="call in calls" :key="call.id" class="rpc-call">
        <div>
          id:
          <span class="rpc-call__id">{{ call.id }}</span>
        </div>
        <div>
          request:
          <span class="rpc-call__request">{{
            JSON.stringify(call.request)
          }}</span>
        </div>
        <div v-if="call.response != null">
          response:
          <span class="rpc-call__response">{{
            JSON.stringify(call.response)
          }}</span>
        </div>
        <div v-if="call.error != null">
          error:
          <span class="rpc-call__error">{{ call.error }}</span>
        </div>
        <div v-if="call.time != null">
          time:
          <span class="rpc-call__time">{{ call.time }}</span> ms
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import Vue from 'vue'
import checkboxBase from '@/components/atoms/checkbox-base.vue'

export default {
  components: { checkboxBase, buttonMain },
  name: 'WebSocketRpc',
  data() {
    return {
      request: '{}',
      calls: [],
      timeout: 50,
      strictMatchActionName: false
    }
  },
  methods: {
    async remoteCall() {
      const id = this.calls.length
      const request = JSON.parse(this.request)
      const t0 = Date.now()
      const call = {
        id,
        request
      }
      this.calls.push(call)
      try {
        const actionName = this.strictMatchActionName
          ? request.action
          : undefined
        const response = await Promise.race([
          this.$sendMessageAndReceive(request, actionName),
          new Promise((resolve) =>
            setTimeout(resolve, this.timeout * 1000)
          ).then(() => {
            throw new Error('timeout')
          })
        ])
        const t1 = Date.now()
        const time = t1 - t0
        Vue.set(call, 'response', response)
        Vue.set(call, 'time', time)
      } catch (e) {
        const t1 = Date.now()
        const time = t1 - t0
        const message = String(e)
        Vue.set(call, 'error', message)
        Vue.set(call, 'time', time)
      }
    },
    reset() {
      this.calls = []
    }
  }
}
</script>

<style scoped>
input[type='number'] {
  text-align: right;
}
.web-socket-rpc {
  display: flex;
}
.rpc-form > label {
  display: block;
}
.rpc-form {
  padding: 0 1em;
}
.rpc-calls {
  overflow: auto;
  width: 80em;
  height: 40em;
  padding: 0 1em;
}
.rpc-call {
  margin: 1em 0;
}
</style>
