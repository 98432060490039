<template>
  <div class="dev-uploader">
    <input
      ref="uploadLearningData"
      type="file"
      class="upload-file"
      placeholder="ファイルを選択"
      @change="inputFile($event.target.files[0])"
    >
    <p>
      selected:
      <span v-if="uploadFile != null" class="value-selected">
        {{ uploadFile.name }}
      </span>
    </p>
    <p>
      status:
      <span :class="`value-status--${progressStatus}`">
        {{ progressStatus }}
      </span>
    </p>
    <p>
      finished:
      <span
        v-if="progressStatus == 'finished' || progressStatus == 'error'"
        class="value-finished"
        v-text="progressStatus"
      />
    </p>
    <p>
      progress:<span class="value-progress">{{ progress }}</span>
    </p>
    <p>
      maxProgress:<span class="value-max-progress">{{ maxProgress }}</span>
    </p>
    <p>
      warnings:<span class="value-warnings">{{ warnings }}</span>
    </p>
    <p>
      dataId:<span class="value-data-id">{{ dataId }}</span>
    </p>

    <input
      id="upload-dev-name"
      v-model="newData.name"
      v-bind:disabled="progress > 0"
      type="text"
      placeholder="dataset name"
    >
    <input
      id="upload-dev-project-id"
      v-model="newData.projectId"
      v-bind:disabled="progress > 0"
      type="text"
      placeholder="project id"
    >
    <textarea
      id="upload-dev-description"
      v-model="newData.description"
      v-bind:disabled="progress > 0"
      placeholder=""
      :rows="3"
      :max-rows="6"
    />

    <br >
    <button-main
      class="upload-button"
      :disabled="uploading"
      size="min"
      text="アップロード"
      @click="uploadData"
    />
    <button-main
      class="reset-button"
      :disabled="uploading"
      size="min"
      text="リセット"
      type="emphasis"
      @click="clear"
    />
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import { uploadViaSocket } from '@/lib/upload.js'

export default {
  components: { buttonMain },
  name: '',
  props: {},
  data() {
    return {
      progress: 0,
      selectedFile: null,
      maxProgress: 1,
      progressStatus: null,
      uploading: false,
      uploadFile: null,
      dataId: null,
      newData: {
        name: '',
        description: '',
        projectId: ''
      },
      warnings: []
    }
  },
  computed: {},
  methods: {
    clear() {
      this.uploadFile = null
      this.progress = 0
      this.selectedFile = null
      this.maxProgress = 1
      this.progressStatus = null
      this.uploading = false
      this.dataId = null
      this.warnings = []
      this.newData = {
        name: '',
        description: '',
        projectId: ''
      }
    },
    async uploadData() {
      const fileSize = this.uploadFile.size
      this.maxProgress = fileSize
      const fileName = this.uploadFile.name
      const request = {
        action: 'startUploading',
        name: this.newData.name,
        description: this.newData.description,
        projectId: this.newData.projectId,
        fileSize: fileSize,
        fileName: fileName
      }
      this.progress = 0
      this.maxProgress = fileSize
      this.progressStatus = 'startUploading'
      this.uploading = true
      const startRes = await this.$sendMessageAndReceive(request)
      try {
        const messageId = startRes.m_id
        if (startRes.status === 'error') {
          throw startRes
        }

        const uploadTask = uploadViaSocket(this.$socket, this.uploadFile, {
          chunkSize: 1e5,
          header: { action: 'uploadingData', m_id: messageId }
        })

        await Promise.all([
          uploadTask,
          this.$watchProgress(messageId, {
            getTotalProgress: (res) => {
              this.progressStatus = 'preparingData'
              this.progress = 0
              this.maxProgress = res.total
            },
            startPreparingZipData: (res) => {
              this.progressStatus = 'preparingData'
              this.progress = 0
              this.maxProgress = 1
            },
            uploadingLearningData: (res) => {
              this.progressStatus = 'uploadingLearningData'
              this.progress = res.loadedSize
              this.maxProgress = res.totalSize
            },
            startPreparingData: (res) => {
              this.progressStatus = 'preparingNumData'
              this.progress = 0
              this.maxProgress = 0
            },
            preparingData: (res) => {
              this.progress = res.progress
            },
            dataWarning: (res) => {
              this.warnings = res.warnings
            },
            finishPreparingData: (res) => {
              if (res.dataId) this.dataId = res.dataId
            },
            uploaded: (res) => {
              if (res.dataId) this.dataId = res.dataId
            }
          })
        ])
        this.progress = this.maxProgress
        this.progressStatus = 'finished'
      } catch (ex) {
        this.progressStatus = 'error'
      } finally {
        this.uploading = false
      }
    },
    inputFile(file) {
      this.uploadFile = file
    }
  }
}
</script>
