<template>
  <div class="dev-image-uploader">
    <input
      ref="uploadEffectImage"
      type="file"
      accept="image/*"
      class="upload-image"
      placeholder="ファイルを選択"
      @change="inputFile($event.target.files[0])"
    >

    <p>
      selected:
      <span v-if="uploadFile != null" class="value-selected">
        {{ uploadFile.name }}
      </span>
    </p>

    <p>
      status:
      <span :class="`value-status--${progressStatus}`">
        {{ progressStatus }}
      </span>
    </p>

    <p>
      finished:
      <span
        v-if="progressStatus == 'finished' || progressStatus == 'error'"
        class="value-finished"
      >
        {{ progressStatus }}
      </span>
    </p>

    <p>
      progress:
      <span class="value-progress">
        {{ progress }}
      </span>
    </p>

    <p>
      maxProgress:
      <span class="value-max-progress">
        {{ maxProgress }}
      </span>
    </p>

    <p>
      warnings:
      <span class="value-warnings">
        {{ warnings }}
      </span>
    </p>

    <input
      id="image-uploader-project-template-id"
      v-model="newData.projectTemplateId"
      v-bind:disabled="progress > 0"
      placeholder="project template id"
      type="text"
    >
    <input
      id="image-uploader-template-image-index"
      v-model="newData.imageIndex"
      v-bind:disabled="progress > 0"
      placeholder="image index"
      type="text"
    >

    <br >
    <button-main
      class="upload-button"
      :disabled="uploading"
      size="min"
      text="アップロード"
      @click="addTemplateEffectImage"
    />
    <button-main
      class="edit-button"
      :disabled="uploading"
      size="min"
      text="編集"
      @click="updateTemplateEffectImage"
    />
    <button-main
      class="reset-button"
      :disabled="uploading"
      size="min"
      text="リセット"
      @click="clear"
    />
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import { uploadViaSocket } from '@/lib/upload.js'

export default {
  components: { buttonMain },
  name: '',
  props: {},
  data() {
    return {
      progress: 0,
      selectedFile: null,
      maxProgress: 1,
      progressStatus: null,
      uploading: false,
      uploadFile: null,
      newData: {
        fileName: '',
        fileSize: '',
        imageIndex: null,
        projectTemplateId: ''
      },
      warnings: []
    }
  },
  computed: {},
  methods: {
    clear() {
      this.uploadFile = null
      this.progress = 0
      this.selectedFile = null
      this.maxProgress = 1
      this.progressStatus = null
      this.uploading = false
      this.dataId = null
      this.warnings = []
      this.newData = {
        name: '',
        description: '',
        projectId: ''
      }
    },
    addTemplateEffectImage: async function () {
      const fileName = this.uploadFile.name
      const fileSize = this.uploadFile.size

      this.maxProgress = fileSize
      this.progress = 0
      this.progressStatus = 'startUploading'
      this.uploading = true

      const request = {
        action: 'startImageUploading',
        projectTemplateId: this.newData.projectTemplateId,
        imageIndex: parseInt(this.newData.imageIndex),
        fileSize: fileSize,
        fileName: fileName
      }
      const startRes = await this.$sendMessageAndReceive(request)

      try {
        const messageId = startRes.m_id
        if (startRes.status === 'error') {
          throw startRes
        }

        const uploadTask = uploadViaSocket(this.$socket, this.uploadFile, {
          chunkSize: 1e5,
          header: {
            action: 'uploadingData',
            m_id: messageId
          }
        })

        await Promise.all([
          uploadTask,
          this.$watchProgress(messageId, {
            getTotalProgress: (res) => {
              this.progressStatus = 'preparingData'
              this.progress = 0
              this.maxProgress = res.total
            },
            startPreparingZipData: (res) => {
              this.progressStatus = 'preparingData'
              this.progress = 0
              this.maxProgress = 1
            },
            uploadingLearningData: (res) => {
              this.progressStatus = 'uploadingLearningData'
              this.progress = res.loadedSize
              this.maxProgress = res.totalSize
            },
            startPreparingData: (res) => {
              this.progressStatus = 'preparingNumData'
              this.progress = 0
              this.maxProgress = 0
            },
            preparingData: (res) => {
              this.progress = res.progress
            },
            dataWarning: (res) => {
              this.warnings = res.warnings
            },
            finishPreparingData: (res) => {
              if (res.dataId) this.dataId = res.dataId
            },
            uploaded: (res) => {
              if (res.dataId) this.dataId = res.dataId
            }
          })
        ])
        this.progress = this.maxProgress
        this.progressStatus = 'finished'
      } catch (ex) {
        this.progressStatus = 'error'
      } finally {
        this.uploading = false
      }
    },
    updateTemplateEffectImage: async function () {
      const fileName = this.uploadFile.name
      const fileSize = this.uploadFile.size

      this.maxProgress = fileSize
      this.progress = 0
      this.progressStatus = 'startUploading'
      this.uploading = true

      const request = {
        action: 'updateProjectTemplateEffectImage',
        projectTemplateId: this.newData.projectTemplateId,
        imageIndex: parseInt(this.newData.imageIndex),
        fileSize: fileSize,
        fileName: fileName
      }
      const startRes = await this.$sendMessageAndReceive(request)

      try {
        const messageId = startRes.m_id
        if (startRes.status === 'error') {
          throw startRes
        }

        const uploadTask = uploadViaSocket(this.$socket, this.uploadFile, {
          chunkSize: 1e5,
          header: {
            action: 'uploadingData',
            m_id: messageId
          }
        })

        await Promise.all([
          uploadTask,
          this.$watchProgress(messageId, {
            getTotalProgress: (res) => {
              this.progressStatus = 'preparingData'
              this.progress = 0
              this.maxProgress = res.total
            },
            startPreparingZipData: (res) => {
              this.progressStatus = 'preparingData'
              this.progress = 0
              this.maxProgress = 1
            },
            uploadingLearningData: (res) => {
              this.progressStatus = 'uploadingLearningData'
              this.progress = res.loadedSize
              this.maxProgress = res.totalSize
            },
            startPreparingData: (res) => {
              this.progressStatus = 'preparingNumData'
              this.progress = 0
              this.maxProgress = 0
            },
            preparingData: (res) => {
              this.progress = res.progress
            },
            dataWarning: (res) => {
              this.warnings = res.warnings
            },
            finishPreparingData: (res) => {
              if (res.dataId) this.dataId = res.dataId
            },
            uploaded: (res) => {
              if (res.dataId) this.dataId = res.dataId
            }
          })
        ])
        this.progress = this.maxProgress
        this.progressStatus = 'finished'
      } catch (ex) {
        this.progressStatus = 'error'
      } finally {
        this.uploading = false
      }
    },
    inputFile(file) {
      this.uploadFile = file
    }
  }
}
</script>
