<template>
  <div class="dev-learner">
    <p>
      status:<span :class="`value-status--${trainingProgressStatus}`">{{
        trainingProgressStatus
      }}</span>
    </p>
    <p>
      finished:<span
        v-if="trainingProgressStatus == 'finishTraining'"
        class="value-finished"
      >finished</span>
    </p>
    <p>
      progress:<span class="value-progress">{{ trainingProgress }}</span>
    </p>
    <p>
      maxProgress:<span class="value-max-progress">{{ trainingNumIter }}</span>
    </p>
    <p>
      modelId:<span class="value-model-id">{{ modelId }}</span>
    </p>

    <textarea
      v-model="learningJSON"
      class="form-textarea"
      :disabled="trainingProgress > 0"
      placeholder=""
      :rows="3"
      :max-rows="6"
    />

    <br >
    <button-main
      class="start-button"
      :disabled="training"
      size="min"
      text="学習開始"
      @click="startLearning"
    />
    <button-main
      class="stop-button"
      :disabled="training"
      size="min"
      text="学習中止"
      @click="stopTrainingAsync"
    />
    <button-main
      class="reset-button"
      :disabled="training"
      size="min"
      text="リセット"
      @click="clear"
    />
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import mixinPolling from '@/mixin/polling.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { buttonMain },
  name: '',
  props: {},
  mixins: [mixinPolling],
  data() {
    return {
      learningJSON: ''
    }
  },
  computed: {
    ...mapGetters('trainings', [
      'training',
      'trainingProgressStatus',
      'trainingProgress',
      'trainingNumIter',
      'modelInfo',
      'elapsedTime',
      'results',
      'charts',
      'bestParams',
      'bestAcc',
      'trials',
      'confusionMatrixList',
      'wordCloud',
      'frequencies',
      'inputColumnsOptions',
      'recipeType',
      'loadingColumns',
      'jobId'
    ]),
    modelId() {
      return this.modelInfo && this.modelInfo.id
    }
  },
  methods: {
    ...mapActions('trainings', [
      'trainingAsync',
      'initCharts',
      'resetResults',
      'fetchValidColumns',
      'fetchRecipeType',
      'resetValidColumns',
      'stopTrainingAsync',
      'pollingAsync'
    ]),
    clear() {
      this.learningJSON = ''
      this.resetResults()
    },
    startLearning() {
      this.trainingAsync(JSON.parse(this.learningJSON))

      this.$polling_startPolling(async () => {
        await this.pollingAsync({
          jobId: this.jobId
        })
        if (!this.training) {
          this.$polling_stopPolling()
        }
      })
    }
  }
}
</script>
